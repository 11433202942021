export default {
  init() {
    openCookiePreference()
    menuOpen()
    stageScroll()
    scrollDown()
    init_smooth_scrolling_anchors()

    $(window).load(function() {
      scrollToAnchor()
    })
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
  },
}

var targetOffset = -80

function scrollToAnchor() {
  console.log(targetOffset) //eslint-disable-line
  if (window.location.hash) {
    var target = $(window.location.hash)
    target = target.length ? target : $('[name="' + this.hash.slice(1) + '"]')
    if (target.length) {
      var target_pos = target.offset().top
      $('html,body').animate(
        {
          scrollTop: target_pos + targetOffset,
        },
        1000
      )
      return false
    }
  }
}

function init_smooth_scrolling_anchors() {
  console.log(targetOffset) //eslint-disable-line
  $(document).on(
    'click',
    'a[href*="#"]:not([href="#"]):not([href*="#vc_images-carousel"]):not(.dhsv_vc_anchor_menu a):not(.collapse_ac):not([data-vc-tabs]):not([data-vc-accordion])',
    function() {
      if (
        location.pathname.replace(/^\//, '') ==
          this.pathname.replace(/^\//, '') &&
        location.hostname == this.hostname
      ) {
        var target = $(this.hash)
        //var scrollTop = $(window).scrollTop()
        target = target.length
          ? target
          : $('[name="' + this.hash.slice(1) + '"]')
        if (target.length) {
          var target_pos = target.offset().top
          $('html,body').animate(
            {
              scrollTop: target_pos + targetOffset,
            },
            1000
          )
          return false
        }
      }
    }
  )
}

function openCookiePreference() {
  $('ul li.borlabs-cookie-preference > a').on(
    'click',
    window.BorlabsCookie.openCookiePreference
  )
}

function menuOpen() {
  $('aside').each(function() {
    const $el = $(this)
    const $nav = $('nav')
    const $menuIcon = $el.find('.menuicon')
    const $menuClose = $nav.find('.close-menu, .menu-item a')
    $menuIcon.click(function() {
      $nav.addClass('open')
    })

    $menuClose.click(function() {
      $nav.removeClass('open')
    })
  })
}

function stageScroll() {
  $(window).scroll(function() {
    const scrollTop = $(window).scrollTop()

    $('.overlay-brand .akzent, .imagewrapp .scrolldown').css({
      opacity: 1 - scrollTop / 500,
    })

    $('.imagewrapp').each(function() {
      $(this)
        .find('.image')
        .css({
          transform: 'translateY(' + scrollTop / 2 + 'px' + ')',
        })
    })
  })
}

function scrollDown() {
  $('.imagewrapp .scrolldown').click(function() {
    $('html, body').animate({ scrollTop: $(window).height() }, 500)
  })
}
