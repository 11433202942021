import React from 'react'
import PropTypes from 'prop-types'
//import { buildUrl } from 'react-instafeed'
import './style.scss'

// const options = {
//   accessToken:
//     'IGQVJXaE01aENOV0l1djE0VmFpbDlmZAXhhckI3a1hMRUFkYWp5UE9oZA25XQktzckxST2w3a0g5MXRmWlBwa3BrRFhkNTZA6cWdCMGdHSjZAockFQVXRKV0NZAbGlNTXQ2VjdRbmxIUGFDMWxXQ0xhWjhsWE45Q3BIRkJpN0ww',
//   clientId: 'e23bee0053da41afb36ed0f0beae4511',
//   get: 'user', // popular, user
//   locationId: null,
//   resolution: 'standard_resolution', // thumbnail, low_resolution, standard_resolution
//   sortBy: 'none', // none, least-commented, least-liked, least-recent, most-commented, most-liked, most-recent, random
//   tagName: null,
// }

const url =
  'https://graph.instagram.com/17841412237046485/media?fields=caption,id,media_type,media_url,permalink,thumbnail_url,username,timestamp&access_token=IGQWROeklLX21JbGlQLU5xTld6enFoN2pUdXFaRnlGcGJTUUhEZAmY5emRPc1AwMHdQS3VET0lTWW5sRXJKbVpVZAk43ejlrVnlhbjQwa3Ntcmk5aWdYcW4zM0ZA4SXlSVmI3NnZAEN2pDODVaenM0VXA3ZAUFxUllEVXcZD'
const COUNT = 9

class Instagram extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      data: false,
      more: false,
    }
    this.getData = this.getData.bind(this)
  }

  componentDidMount() {
    this.getData()
  }

  getData() {
    fetch(url)
      .then(response => {
        response.json().then(responseData => {
          this.setState({
            data: responseData,
          })
        })
      })
      .catch(error => {
        console.warn(error) //eslint-disable-line
      })
  }

  render() {
    const { data, more } = this.state

    return data ? (
      <div className="dhsv_instagram_grid">
        {data.data.slice(0, more ? data.data.length : COUNT).map(item => {
          return (
            <a
              href={item.permalink}
              target="_blank"
              key={item.id}
              className="item"
            >
              <img className="igimage" src={item.media_url} />
              <div className="igcontent">
                <span className="caption">{item.caption}</span>
              </div>
            </a>
          )
        })}
        <div className="button-wrapper">
          <a
            href="https://www.instagram.com/metromarketsgmbh/"
            target="_blank"
            rel="noopener noreferrer"
            className="button"
          >
            Instagram
          </a>
          <button hidden={true} onClick={() => this.setState({ more: !more })}>
            {more ? 'Weniger anzeigen' : 'Mehr anzeigen'}
          </button>
        </div>
      </div>
    ) : (
      <div className="dhsv_instagram_grid loader">
        Loading Instagram Grid...
      </div>
    )
  }
}

Instagram.propTypes = {
  isMobile: PropTypes.bool,
}

export default Instagram
